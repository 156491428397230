<template>
  <Dialog :is-dialog-exist="showDialog" :title="'提现'" :dialog-style="dialogStyle"
          @close-dialog="handleClose">
    <el-container>
      <el-main>
        <el-form ref="formData" :model="formData" :rules="rules" label-width="auto">
          <el-form-item label="发票类型:" prop="alipay">
            <el-radio label="1" v-model="formData.has_invoice" disabled>开发票</el-radio>
            <el-radio label="0" v-model="formData.has_invoice">不开发票</el-radio>
          </el-form-item>
          <el-form-item label="提现金额:" prop="amount">
            <el-input v-model="formData.amount" class="control-input" placeholder="请输入" clearable>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="formData.has_invoice === '1' " label="税率:" prop="invoice_bili">
            <el-input v-model="formData.invoice_bili" class="control-input" placeholder="请输入">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <div>
          <el-button type="primary" size="medium" @click="submitForm">保存</el-button>
          <el-button size="medium" @click="handleClose">取消</el-button>
        </div>
      </el-footer>
    </el-container>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog/index'
import request from "@/utils/request";

export default {
  components: {Dialog},
  data() {
    return {
      // 弹窗控制参数
      showDialog: false,
      // dialog样式
      dialogStyle: {
        '--dialog-width': '512px',
        '--dialog-height': '342px',
        '--title-height': '51px'
      },
      // 提交参数
      formData: {
        "type": "playlet",
        "amount": "",
        "has_invoice": "",
        "invoice_bili": ""
      },
      // 校验规则
      rules: {
        has_invoice: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        amount: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        invoice_bili: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    show(type) {
      this.formData = {
        "type": type === 'amount' ?  "playlet" : type,//固定
        "amount": "",
        "has_invoice": "0",//是否有发票
        "invoice_bili": ""//发票比例%
      }
      this.showDialog = true;
    },
    // 关闭弹窗
    handleClose() {
      this.showDialog = false;
    },
    // 提交表单
    submitForm() {
      let params = {...this.formData}
      params.invoice_bili = params.invoice_bili + ''
      this.$refs.formData.validate(valid => {
        if (valid) {
          request({
            url: '/api/admin/withdrawal_doing',
            method: 'POST',
            data: params
          }).then(resp => {
            if (resp === 'success') {
              this.$emit('on-success')
              this.$message.success('操作成功')
              this.handleClose();
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;

  .el-main {
    width: 100%;
    height: 90%;
    padding-left: 10%;
    padding-right: 10%;
  }
}


.el-footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
}
</style>



