<template>
  <el-button
    ref="btn"
    :size="size"
    :type="type"
    :plain="plain"
    :round="round"
    :circle="circle"
    :loading="loading"
    :disabled="disabled"
    :icon="icon"
    :class="background ? 'background' : ''"
    @click="handleClick"
  >
    <slot name="icon"></slot>
  </el-button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    type: {
      type: String,
      default: 'primary'
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.$refs.btn.$el.onkeydown = (e) => {
      // const key = window.event.keyCode
      // if (key === 13) {
      //   return false
      // }
      return false
    }
  },
  methods: {
    handleClick(el) {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .svg-icon {
  width: 1.2em;
  height: 1.2em;
  margin: 0 auto;
}

/** 默认 */
.el-button--primary.is-active,
.el-button--primary:active {
  background: #3da2ff;
  border-color: #3da2ff;
  color: #fff;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #3da2ff;
  border-color: #3da2ff;
  color: #ffffff;
}

.el-button--primary {
  color: #3da2ff;
  background-color: transparent;
  border-color: transparent;
}

.el-button--primary.background {
  color: #3da2ff;
  background-color: #e8f4ff;
  border-color: #e8f4ff;
}

/** 红色警告 */

.el-button--danger.is-active,
.el-button--danger:active {
  background: #ff4949;
  border-color: #ff4949;
  color: #fff;
}

.el-button--danger:focus,
.el-button--danger:hover {
  background: #ff4949;
  border-color: #ff4949;
  color: #fff;
}

.el-button--danger {
  color: #ff4949;
  background-color: transparent;
  border-color: transparent;
}
.el-button--danger.background {
  color: #ff4949;
  background-color: #ffeded;
  border-color: #ffeded;
}

/** 黄色警告 */

.el-button--warning.is-active,
.el-button--warning:active {
  background: #FFBB00;
  border-color: #FFBB00;
  color: #fff;
}

.el-button--warning:focus,
.el-button--warning:hover {
  background: #FFBB00;
  border-color: #FFBB00;
  color: #fff;
}

.el-button--warning {
  color: #FFBB00;
  background-color: transparent;
  border-color: transparent;
}
.el-button--warning.background {
  color: #FFBB00;
  background-color: #ffeded;
  border-color: #ffeded;
}

/** 绿色成功 */

.el-button--success.is-active,
.el-button--success:active {
  background: #13ce66;
  border-color: #13ce66;
  color: #fff;
}

.el-button--success:focus,
.el-button--success:hover {
  background: #13ce66;
  border-color: #13ce66;
  color: #fff;
}

.el-button--success {
  color: #13ce66;
  background-color: transparent;
  border-color: transparent;
}
.el-button--success.background {
  color: #13ce66;
  background-color: #e7faf0;
  border-color: #e7faf0;
}

/** info成功 */

.el-button--info.is-active,
.el-button--info:active {
  background: #909399;
  border-color: #909399;
  color: #fff;
}

.el-button--info:focus,
.el-button--info:hover {
  background: #909399;
  border-color: #909399;
  color: #fff;
}

.el-button--info {
  color: #909399;
  background-color: transparent;
  border-color: transparent;
}
.el-button--info.background {
  color: #909399;
  background-color: #909399;
  border-color: #909399;
}

/** 禁用的样式 **/
button.el-button.el-tooltip.is-disabled {
  background-color: transparent;
  border-color: transparent;
  color: #DCDFE6;
}
</style>
