import request from '@/utils/request'
import axios from 'axios'

class CommonSet {
  /**
   * 上传文件
   *
   * @param {*} file file文件
   * @param {*} fileId 文件夹id，空为根目录
   * @param {*} modName 模块/文件夹名称，用于直接上传时模块分类
   * @memberof CommonSet
   */
  uploadFile(file, fileId, modName) {
    // 图片限制2M以内
    if (file.raw ? file.raw.type.indexOf('image') !== -1 : file.type.indexOf('image') !== -1) {
      // 2M
      if (file.size > 4 * 1024 * 1024) {
        return Promise.reject('图片大小超过4M～')
      } else {
        console.log('没超过')
      }
    }
    // file.name = this.cn2en(file.name)
    const params = { file_name: file.name }
    params['dir_name'] = modName ?? ''
    params['file_size'] =file.size
    return this._getUploadToken(params).then(response => {
      const { key, host, token } = response
      // 修复协议错误
      let newScheme = 'http'
      if (window.location.protocol === 'https:') {
        newScheme = 'https'
      }
      return this._uploadFile(
        newScheme + '://' + host,
        'post',
        key,
        token,
        file.raw ? file.raw : file
      ).then(res => {
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
    }).catch(err => {
      return Promise.reject(err.message)
    })
  }

  // /**
  //  * 移除特殊字符
  //  */
  // cn2en(chineseChar) {
  //   // 将单引号‘’都转换成'，将双引号“”都转换成"
  //   var str = chineseChar.replace(/\’|\‘/g, '').replace(/\“|\”/g, '')
  //   // 将中括号【】转换成[]，将大括号｛｝转换成{}
  //   str = str.replace(/\【/g, '[').replace(/\】/g, ']').replace(/\｛/g, '{').replace(/\｝/g, '}')
  //   // 将逗号，转换成,，将：转换成:
  //   str = str.replace(/，/g, ',').replace(/：/g, ':')
  //   str = str.replace(/\（/g, '(').replace(/\）/g, ')')
  //   str = str.replace(' ', '').replace('@', '')
  //   return str
  // }



  /**
   * 获取文件上传参数，私有方法，尽量不用调用
   *
   */
  _getUploadToken(params) {
    return request({
      url: '/api/admin/file/upload/token',
      method: 'post',
      // headers: { 'Content-Type': 'text/plain' },
      data: params
    })
  }

  // 上传文件
  _uploadFile(url, method, filename, token, file) {
    var formData = new FormData()
    formData.append('key', filename)
    formData.append('token', token)
    formData.append('file', file)
    // 使用axios请求，防止出现跨域问题导致上传失败
    return axios(url, {
      method: method,
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },

      data: formData,
      withCredentials: false,
      credentials: 'same-origin'
    }).then(response => {
      return Promise.resolve(response.data)
    }).catch(error => {
      console.log('upload file error msg', error.response.data)
      console.log('upload file error code', error.response.status)
      console.log('upload file error header', error.response.headers)

      if (error.response.status === 614) {
        return Promise.reject(new Error('上传的文件已修改且文件名重复，请修改文件名后再上传'))
      } else if (error.response.status === 579) {
        return Promise.reject(new Error('上传成功但是回调失败'))
      } else {
        return Promise.reject(new Error('上传错误，错误状态码：' + error.response.status))
      }
    })
  }
}

export default CommonSet
