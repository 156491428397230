<template>
  <el-main class="my-card">
    <el-row :gutter="10">
      <el-col v-for="(item,index) in incomeData" :key="index" :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div class="card-item-widget">
          <div class="card-mod-tile">{{ item.name }}</div>
          <div class="data-card">
            <el-image :src="index==0?'./pic/data-icon.png':'./pic/data-icon-1.png'" class="data-img"/>
            <div class="tip-bind-str">
              <template v-if="item.is_show_withdrawal==='1'">
                <span style="color: #E84646; " v-if="item.is_need_bing_alipay==='1'">绑定支付宝后可提现</span>
                <span style="color: #969696; " v-if="item.is_need_bing_alipay==='0'">已绑定支付宝</span>
              </template>
            </div>
            <div class="data">{{ item.value }}</div>
            <div class="income-widget">
              <div class="count-income">{{ item.tip }}</div>
              <div class="bind-btn">
                <template v-if="item.is_show_withdrawal==='1'">
                  <el-button size="mini" type="primary" v-if="item.is_need_bing_alipay==='1'" @click="bindAliPay(item)">绑定支付宝</el-button>
                  <el-button size="mini" type="primary" v-if="item.is_need_bing_alipay==='0'" @click="bindAliPay(item)">更换支付宝</el-button>
                  <el-button size="mini" type="primary" v-if="item.is_need_bing_alipay==='0'" @click="withdrawal(item.type)">提现</el-button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col v-for="(_,index) in 3" :key="'index'+index" :xs="24" :sm="12" :md="12" :lg="6" :xl="6"/>
    </el-row>
    <el-alert
        title="每月25-30号结算上月1-30号结算的订单"
        type="warning">
    </el-alert>
    <HeadTitle title="提现记录" style="margin-top: 20px;"/>
    <el-card shadow="never">
      <el-form ref="formData" size="medium" :model="formData" label-width="110px">
        <div class="flex-raw">
          <el-form-item label="支付宝账号" prop="status">
            <el-input v-model="formData.alipay" clearable/>
          </el-form-item>
          <el-form-item label="提现状态" prop="status">
            <el-select v-model="formData.status" clearable>
              <el-option v-for="(item,index) in records.status_list" :label="item.name" :value="item.value" :key="index"/>
            </el-select>
          </el-form-item>
          <el-form-item label="审核时间" prop="robot_type">
<!--            <el-date-picker-->
<!--                v-model="formData.dateRange"-->
<!--                type="datetimerange"-->
<!--                format="yyyy-MM-dd HH:mm:ss"-->
<!--                value-format="yyyy-MM-dd"-->
<!--                unlink-panels-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                :picker-options="pickerOptions">-->
<!--            </el-date-picker>-->
            <CustomChoiceDatePicker
                date-type="datetimerange"
                @handelChange="(val) => handleTime('dateRange', val)"
            />
          </el-form-item>
          <el-form-item label="提现时间" prop="robot_type">
<!--            <el-date-picker-->
<!--                v-model="formData.withDrawRange"-->
<!--                type="datetimerange"-->
<!--                format="yyyy-MM-dd HH:mm:ss"-->
<!--                value-format="yyyy-MM-dd"-->
<!--                unlink-panels-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                :picker-options="pickerOptions">-->
<!--            </el-date-picker>-->
            <CustomChoiceDatePicker
                date-type="datetimerange"
                @handelChange="(val) => handleTime('withDrawRange', val)"
            />
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" size="medium" @click="onSubmit">搜索</el-button>
            <el-button size="medium" @click="_reset">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-divider></el-divider>
      <div class="el-button-container">
        <div class="left-button">
          <el-button type="primary" icon="el-icon-refresh-right" size="medium" @click="fetchOrders(true)"/>
          <el-button type="primary" size="medium" @click="exportHandler">导出Excel表格</el-button>
        </div>
      </div>
      <el-table border :data="records.list" style="width: 100%" v-loading="loading">
        <el-table-column label="提现账号" align="center" min-width="180" prop="alipay">
        </el-table-column>
        <el-table-column label="支付宝名称" align="center" min-width="150" prop="alipay_name">
        </el-table-column>
        <el-table-column label="提现金额" align="center" min-width="150" prop="source">
          <template slot-scope="scope">
            <span style="color: #F98B2D">¥{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现手续费" align="center" min-width="150" prop="source">
          <template slot-scope="scope">
            <span style="color: #F98B2D">¥{{ scope.row.fee }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现时间" align="center" min-width="180" prop="time"/>
        <el-table-column label="审核时间" align="center" min-width="180" prop="check_time"/>
        <el-table-column label="提现状态" align="center" min-width="120">
          <template scope="scope">
            <span style="color: red">{{ scope.row.status }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center" min-width="200" prop="remark">
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="200" fixed="right">
          <template scope="scope">
            <div style="font-size: 14px; ">
              <span style="color: #327CFC;margin-right: 21px" class="pointer" @click="handlerDetail(scope.row)">查看明细</span>
              <template v-if="scope.row.is_need_upload_invoice==='1'">
                <span style="color: #327CFC;" class="pointer" v-if="scope.row.img===''" @click="uploadImg(scope.row.id)">上传发票</span>
                <span class="upload" style="color: #6E6E6E;" v-else>已上传</span>
              </template>
            </div>
            <span @click="handlerDetail(scope.row)"></span>
          </template>
        </el-table-column>
      </el-table>
      <el-footer class="el-pagination-footer">
        <el-pagination :current-page="formData.page"
                       :page-sizes="[ 5, 10, 15, 20, 30, 50, 100, 200]"
                       :page-size="formData.size"
                       layout="total, prev, pager, next, sizes, jumper"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </el-footer>
    </el-card>
    <AddAlipayAccountDialog ref="addAliPayDialog" @on-success="_fetchDataCount"/>
    <WithDrawalDialog ref="withDrawalDialog" @on-success="_fetchDataCount"/>
    <DetailedDialog ref="detailedDialog"/>
    <UploadImgDialog ref="uploadImgDialog" @on-success="_fetchDataCount"/>
  </el-main>
</template>

<script>
import request from "@/utils/request";
import HeadTitle from "@/components/head-title";
import pickerOptions from '@/mixins/PickerOptions'
import AddAlipayAccountDialog from "./components/add-alipay-account-dialog";
import WithDrawalDialog from './components/withdrawal-dialog'
import DetailedDialog from './components/detailed-dialog'
import UploadImgDialog from "./components/upload-img-dialog";
import CustomChoiceDatePicker from '@/components/CustomChoiceDatePicker/index'

export default {
  mixins: [pickerOptions],
  name: "order-index",
  components: {
    AddAlipayAccountDialog,
    WithDrawalDialog,
    DetailedDialog,
    UploadImgDialog,
    HeadTitle,
    CustomChoiceDatePicker
  },
  data() {
    return {
      incomeData: [],
      records: {
        list: []
      },
      loading: true,
      total: 0,
      formData: {
        dateRange: [],
        withDrawRange: [],
        "type": "playlet",//固定
        "p": 1,
        "size": 10,
        "start_time": "",//申请时间开始区间
        "end_time": "",//申请时间结束区间
        "check_start_time": "",//审核时间开始区间
        "check_end_time": "",//审核时间结束区间
        "status": "",//状态
        "alipay": ""//支付宝账号
      }
    }
  },
  mounted() {
    this.fetchOrders()
    this._fetchDataCount();
  },
  methods: {
    withdrawal(type) {
      this.$refs.withDrawalDialog.show(type)
    },
    bindAliPay(item) {
      this.$refs.addAliPayDialog.show(item)
    },
    _fetchDataCount() {
      request({
        url: '/api/admin/withdrawal_income',
        method: 'POST',
        data: {"type": "playlet"}
      }).then(res => {
        this.incomeData = res;
      })
    },
    exportHandler() {
      let num = 0
      num = Math.ceil(this.total/ 3000) // 向上取整， 有小数点就取整
      if (num === 0) {
        return this.$message.info('没有查到内容，请重新选择')
      }
      this.$confirm(`一共有${this.total}条数据，确定要下载吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const loading = this.$loading({lock: true, text: '正在准备下载...', spinner: 'el-icon-loading', background: 'rgba(255, 255, 255, 0.7)'})
        let params = {...this.formData}
        delete params.dateRange
        delete params.withDrawRange
        params.size = params.size + ''
        for (let i = 1; i <= num; i++) {
          params.p = i.toString()
          request({
            url: '/api/admin/withdrawal_output',
            method: 'POST',
            data: params
          }).then(res => {
            this.downloadFileByBase64(res.file, res.filename, loading)
          }).catch(() => {
            loading.close()
          })
        }

      }).catch(() => {
      })
    },
    // 导出订单
    dataURLtoBlob(dataUrl) {
      let arr = dataUrl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type: mime})
    },
    downloadFile(url, name = "What's the fuvk", event) {
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', name)
      a.setAttribute('target', '_blank')
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
      event.close()
    },
    async downloadFileByBase64(base64, name, event) {
      const myBlob = this.dataURLtoBlob(base64)
      const myUrl = URL.createObjectURL(myBlob)
      this.downloadFile(myUrl, name, event)
    },
    handlerDetail(row) {
      this.$refs.detailedDialog.show(row)
    },
    uploadImg(id) {
      this.$refs.uploadImgDialog.show(id)
    },
    handleSizeChange(val) {
      this.formData.size = val
      this.fetchOrders()
    },
    handleCurrentChange(val) {
      this.formData.p = val
      this.fetchOrders()
    },
    onSubmit() {

      this.formData.p = 1
      this.fetchOrders()
    },
    _reset() {
      this.$refs.formData.resetFields()
    },
    handleTime(key, val) {
      if (key === 'dateRange') {
        if (val && val.length === 2) {
          this.formData.check_start_time = val[0]
          this.formData.check_end_time = val[1]
        } else {
          this.formData.check_start_time = ''
          this.formData.check_end_time = ''
        }
      } else if (key === 'withDrawRange') {
        if (val && val.length === 2) {
          this.formData.start_time = val[0]
          this.formData.end_time = val[1]
        } else {
          this.formData.start_time = ''
          this.formData.end_time = ''
        }
      }
    },
    fetchOrders(isRefresh = false) {
      if (isRefresh) {
        this.$message.success('刷新成功')
      }
      let params = {...this.formData}
      delete params.dateRange
      delete params.withDrawRange
      params.p = params.p + ''
      params.size = params.size + ''
      request({
        url: '/api/admin/withdrawal_list',
        method: 'POST',
        data: params
      }).then(res => {
        this.total = res.total;
        this.records = res
        this.loading = false;
      })
    }

  }
}
</script>

<style scoped lang="scss">
.my-card {
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}


@media only screen and (max-width: 767px) {
  //.el-col-xs-24 {
  //  width: 100%;
  //}
}

@media only screen and (min-width: 1200px) {
  .el-col-lg-6 {
    width: 20%;
  }
}

@media only screen and (min-width: 992px) {
  //.el-col-md-12 {
  //  width: 50%;
  //}
}

@media only screen and (min-width: 768px) {
  //.el-col-sm-12 {
  //  width: 50%;
  //}
}

.my-card::-webkit-scrollbar {
  display: none;
}

.el-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.card-item-widget {
  margin-bottom: 20px;

  .data-card {
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.03);
    border-radius: 22px;
    padding: 20px;
    margin-top: 20px;
    box-sizing: border-box;

    .data-img {
      width: 28px;
      height: 28px;
      margin-bottom: 2px;
    }

    .tip-bind-str {
      min-height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 16px;
      color: #E84646;
    }

    .income-widget {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #718096;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .bind-btn {
      min-height: 31px;
    }

    .data {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 40px;
      color: #2D3748;
    }

    .bind-alipay {
      width: 89px;
      height: 31px;
      background: #2D9BF9;
      border-radius: 6px;
      text-align: center;
      line-height: 31px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

}

.el-pagination-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

::v-deep .el-range-separator {
  width: 8% !important;
}

.flex-raw {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .el-form-item {
    width: 370px;

    .el-input {
      width: 100%;
    }

    .el-select {
      width: 100%;
    }

    .el-input__inner {
      width: 100%;
    }
  }
}

</style>
