<template>
  <button ref="count-down-btn"
          :class="localBtnClass"
          style="font-size: 12px;"
          @click.prevent="handleClick"
  >
    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: 'CountDownBtn',
  props: {
    btnClass: {
      type: String,
      default: 'blue-btn'
    },
    btnStyle: {
      type: Object,
      default: () => ({ fontSize: 12 + 'px', marginLeft: 3 + 'px' })
    }
  },
  data() {
    return {
      btnText: '获取验证码',
      isDisabled: false,
      localBtnClass: this.btnClass,
      count: 0
    }
  },
  methods: {
    countDown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.isDisabled = true
        this.localBtnClass = 'gray-btn'
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
            this.btnText = '剩余 ' + this.count + ' 秒'
          } else {
            this.isDisabled = false
            clearInterval(this.timer)
            this.timer = null
            this.btnText = '重新发送'
            this.localBtnClass = 'blue-btn'
            this.isDisabled = false
          }
        }, 1000)
      }
    },
    handleClick() {
      if (this.isDisabled === true) {
        return false
      }
      this.$emit('on-click')
    }
  }
}
</script>

<style scoped lang="scss">
.gray-btn {
  color: #999;
}
.gray-btn {
  @extend .line-btn;
  border: 1px solid #ddd;
  color: #999;
  background-color: #eee;
}

.blue-btn{
  @extend .line-btn;
  border: none;
  color: #fff;
  background-color: #1890ff;

  &:hover {
    color: #fff;
  }
}
.line-btn {
  @extend .custom-btn;
  width: 88px;
  height: 28px;
  border-radius: 5px;
  font: 400 14px PingFangSC-Regular, PingFang SC;
}
.custom-btn{
  outline: none;
  cursor: pointer;
}

</style>
