<template>
  <div class="uploader-container">
    <div class="flex-box" :style="previewBoxStyle" style="width: 100px; position: relative;">
      <el-image v-if="image" :src="image" :preview-src-list="[image]" alt="" :style="imageStyle" fit="contain" />
      <el-image v-else :src="imagePlaceholder" :preview-src-list="[imagePlaceholder]" alt="" :style="imageStyle"
                fit="contain" />
      <i v-if="image" class="el-icon-error" style="color: red; position: absolute; right: 0; top: 0; cursor: pointer;" @click="handleClearData" />
    </div>
    <div>
      <el-upload
        class="upload-demo"
        action=""
        :on-change="(file, fileList) => handleImageSelected(file, fileList)"
        :auto-upload="false"
        :show-file-list="false"
      >
        <FNButton
          slot="trigger"
          size="small"
          type="danger"
          plain
          background
          style="width:30px;height:30px;padding:0;margin: 5px;"
        >
          <template slot="icon">
            <svg-icon icon-class="upload" />
          </template>
        </FNButton>
      </el-upload>
    </div>
  </div>
</template>

<script>
/**
 * 会员等级相关图片上传、选择
 * 参数：
 * uploadType: 上传类型 0:普通文件上传 1:im 文件上传 默认普通文件上传
 * image： 图片地址，用于显示
 * field： 操作的字段名
 * imagePlaceholder：默认占位图片
 * previewBoxStyle：预览位置样式
 * imageStyle：图片样式
 */
import CommonSet from '@/utils/CommonSet'
import FNButton from '@/components/FNButton/index'
export default {
  name: 'PicUploader',
  components: {
   FNButton
  },
  props: {
    bgToggleShow: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: [String, Number],
      default: '0'
    },
    field: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    imagePlaceholder: {
      type: String,
      default: '/pic/default-placeholder@2x.png'
    },
    previewBoxStyle: {
      type: Object,
      default: () => ({
        width: 80 + 'px',
        height: 60 + 'px',
        border: 1 + 'px dashed #' + 999,
        margin: 5 + 'px'
      })
    },
    imageStyle: {
      type: Object,
      default: () => ({
        width: 74 + 'px',
        height: 54 + 'px',
        padding: 3 + 'px',
        objectFit: 'contain'
      })
    },
    moduleName: {
      type: String,
      default: 'custom'
    }
  },
  data() {
    return {
      selAssetsVisible: false,
      loading: false,
      commonSetInstance: null
    }
  },
  created() {
    this.commonSetInstance = new CommonSet()
  },
  methods: {
    bgToggleHandler() {
      this.$emit('onBgToggleHandler')
    },
    handleClearData() {
      this.$emit('on-image-uploaded', this.field, '', '')
    },
    showSelectDialog() {
      this.selAssetsVisible = true
    },
    // 选择图片直接上传
    handleImageSelected(file) {
      if (!this.commonSetInstance) {
        this.commonSetInstance = new CommonSet()
      }
      this.loading = true
      console.log('this.uploadType', this.uploadType)
      const methodsName = this.uploadType === '0' || this.uploadType === 0 ? 'uploadFile' : 'uploadImFile'
      this.commonSetInstance[methodsName](file, '', this.moduleName).then(response => {
        const { data } = response
        const { fname } = data
        this.$emit('on-image-uploaded', this.field, fname, file)
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$message.error(error)
      })
    },
    handleSelectionAssets(data) {
      const imageUrl = data[0].imageUrl
      this.$emit('on-image-selected', this.field, imageUrl, data[0])
      this.selAssetsVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.uploader-container {
  display: flex;
  align-items: center;
}

</style>
