<template>
  <Dialog :is-dialog-exist="showDialog" :title="'上传发票'" :dialog-style="dialogStyle"
          @close-dialog="handleClose">
    <el-container>
      <el-main>
        <el-form ref="formData" :model="formData" :rules="rules" label-width="auto">
          <el-form-item label="上传发票:" prop="img">
            <PicUploader field="img" :image="formData.img"
                         @on-image-uploaded="onImageUploaded"></PicUploader>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <div>
          <el-button type="primary" size="medium" @click="submitForm">保存</el-button>
          <el-button size="medium" @click="handleClose">取消</el-button>
        </div>
      </el-footer>
    </el-container>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog/index'
import request from "@/utils/request";
import PicUploader from "@/components/PicUploader";

export default {
  components: {Dialog, PicUploader},
  data() {
    return {
      // 弹窗控制参数
      showDialog: false,
      // dialog样式
      dialogStyle: {
        '--dialog-width': '512px',
        '--dialog-height': '342px',
        '--title-height': '51px'
      },
      // 提交参数
      formData: {
        id: '',
        img: '',
      },
      // 校验规则
      rules: {
        img: [
          {required: true, message: '请上传图片', trigger: 'blur'},
        ]
      },
    }
  },
  methods: {
    show(id) {
      this.formData.id = id;
      this.formData.img = '';
      this.showDialog = true;
    },
    onImageUploaded(field, value) {
      this.formData.img = value;
    },
    // 关闭弹窗
    handleClose() {
      this.showDialog = false;
    },
    // 提交表单
    submitForm() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          request({
            url: '/api/admin/withdrawal_invoice_img',
            method: 'POST',
            data: this.formData
          }).then(resp => {
            if (resp === 'success') {
              this.$emit('on-success')
              this.$message.success('上传成功')
              this.handleClose();
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;

  .el-main {
    width: 100%;
    height: 90%;
    padding-left: 15%;
    padding-right: 15%;
  }
}


.el-footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
}
</style>



