<template>
  <Dialog :is-dialog-exist="showDialog" :title="'明细'" :is-default-full="false" :dialog-style="dialogStyle"
          @close-dialog="handleClose">
    <el-container>
      <el-main v-if="Object.keys(detailedInfo).length>0">
        <div class="info-raw">
          <div class="info-col">
            <div class="info-title">
              提现时间:
            </div>
            <div class="info-value">
              {{ detailedInfo.time }}
            </div>

          </div>
          <div class="info-col">
            <div class="info-title">
              提现账号:
            </div>
            <div class="info-value">
              {{ detailedInfo.alipay }}
            </div>
          </div>
        </div>
        <div class="info-raw">
          <div class="info-col">
            <div class="info-title">
              支付宝名称:
            </div>
            <div class="info-value">
              {{ detailedInfo.alipay_name }}
            </div>
          </div>
          <div class="info-col">
            <div class="info-title">
              提现金额:
            </div>
            <div class="info-value" style="color: #F98B2D">
              {{ detailedInfo.amount }}
            </div>
          </div>
        </div>
        <div class="info-raw">
          <div class="info-col">
            <div class="info-title">
              提现失败原因:
            </div>
            <div class="info-value">
              {{ detailedInfo.reason }}
            </div>
          </div>
          <div class="info-col">
            <div class="info-title">
              备注:
            </div>
            <div class="info-value">
              {{ detailedInfo.remark }}
            </div>
          </div>
        </div>
        <div class="info-raw">
          <div class="info-col">
            <div class="info-title">
              到账金额:
            </div>
            <div class="info-value" style="color: #F98B2D">
              {{ detailedInfo.real_amount }}
            </div>
          </div>
          <div class="info-col">
            <div class="info-title">
              手续费:
            </div>
            <div class="info-value" style="color: #F98B2D">
              {{ detailedInfo.fee ? detailedInfo.fee : '-' }}
            </div>
          </div>
        </div>
        <div class="info-raw">
          <div class="info-col">
            <div class="info-title">
              审核时间:
            </div>
            <div class="info-value">
              {{ detailedInfo.start_time ? detailedInfo.start_time : '-' }}
            </div>
          </div>
          <div class="info-col"  v-if="detailedInfo.img">
            <div class="info-title">
              发票图片:
            </div>
            <div class="info-value" >
              <el-image :src="detailedInfo.img" class="info-img"    :preview-src-list="[detailedInfo.img]"/>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </Dialog>
</template>
<script>
import Dialog from '@/components/Dialog/index'

export default {
  components: {Dialog},
  props: {
    reasonList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 弹窗控制参数
      showDialog: false,
      detailedInfo: {
      },
      // dialog样式
      dialogStyle: {
        '--dialog-width': '896px',
        '--dialog-height': '580px',
        '--title-height': '51px'
      }
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.showDialog = false
    },
    show(row) {
      this.detailedInfo = row
      this.showDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;

  .el-main {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

.info-raw {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-col {
  display: flex;
  align-items: center;

  .info-title {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    width: 100px;
    text-align: right;
  }

  .info-value {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-left: 18px;
    width: 200px;
  }

  .complaint-imgs {
    display: flex;
    flex-wrap: wrap;

    .complaint-img {
      width: 62px;
      height: 62px;
      margin-right: 6px;
    }
  }

  .info-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
</style>
