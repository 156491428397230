<template>
  <Dialog :is-dialog-exist="showDialog" :title="'收款账号-支付宝绑定'" :dialog-style="dialogStyle"
          @close-dialog="handleClose">
    <el-container>
      <el-main>
        <el-form ref="formData" :model="formData" :rules="rules" label-width="auto">
          <el-form-item label="原支付宝账号:" prop="old_alipay" v-if="old_alipay!=''" style="height: 20px">
            {{old_alipay_name}} {{old_alipay}}
          </el-form-item>
          <el-form-item label="输入支付宝账号:" prop="alipay">
            <el-input v-model="formData.alipay" class="control-input" placeholder="输入您的支付宝账号" clearable/>
          </el-form-item>
          <el-form-item label="输入账号绑定姓名:" prop="alipay_name">
            <el-input v-model="formData.alipay_name" class="control-input" placeholder="输入您的姓名" clearable/>
          </el-form-item>
          <el-form-item label="验证码"  prop="captcha">
            <el-input v-model="formData.captcha" class="control-input" placeholder="请输入验证码" clearable/>
            <CountDownBtn ref="countDown" @on-click="sendSms" />
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <div>
          <el-button type="primary" size="medium" @click="submitForm">保存</el-button>
          <el-button size="medium" @click="handleClose">取消</el-button>
        </div>
      </el-footer>
    </el-container>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog/index'
import request from "@/utils/request";
import CountDownBtn from '@/components/Common/CountDownBtn'

export default {
  components: {Dialog,CountDownBtn},
  data() {
    return {
      // 弹窗控制参数
      showDialog: false,
      // dialog样式
      dialogStyle: {
        '--dialog-width': '512px',
        '--dialog-height': '422px',
        '--title-height': '51px'
      },
      // 提交参数
      formData: {
        alipay: '',
        alipay_name: '',
        captcha:''
      },
      old_alipay: '',
      old_alipay_name: '',
      // 校验规则
      rules: {
        alipay: [
          {required: true, message: '输入支付宝账号', trigger: 'blur'},
        ],
        alipay_name: [
          {required: true, message: '输入您的姓名', trigger: 'blur'},
        ],
        captcha: [
          {required: true, message: '输入验证码', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    show(item) {
      this.old_alipay=item.alipay
      this.old_alipay_name=item.alipay_name
      this.showDialog = true;
    },
    // 关闭弹窗
    handleClose() {
      this.showDialog = false;
    },
    sendSms() {
      request({
        url: '/api/admin/sms',
        method: 'GET'
      }).then(resp => {
        this.$message.success('发送成功')
        this.$refs.countDown.countDown()
      }).catch((res) => {
        console.log(res)
        this.$message.error(res.data.msg)
        this.$refs['count-down-btn'].textContent = '重新发送'
        this.$refs['count-down-btn'].classList.remove('gray-btn').add('blue-btn')
      })
    },
    // 提交表单
    submitForm() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          request({
            url: '/api/admin/withdrawal_bind_alipay',
            method: 'POST',
            data: this.formData
          }).then(resp => {
            if (resp === 'success') {
              this.$emit('on-success')
              this.$message.success('绑定成功')
              this.handleClose();
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;

  .el-main {
    width: 100%;
    height: 90%;
    padding-left: 15%;
    padding-right: 15%;
  }
}


.el-footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
}
</style>



